<template>
  <div>
    <Toast :style="{ width: $isMobile() ? '85vw' : '' }" />
    <Dialog
      :visible.sync="show"
      :style="{ width: $isMobile() ? '100vw' : '50vw' }"
      :header="
        expenditureId == null
          ? 'Adicionar Despesa'
          : inApprove == true
          ? `Aprovar Despesa | ${user.username}`
          : editable == true
          ? 'Editar Despesa'
          : 'Ver Despesa'
      "
      :modal="true"
      :closable="false"
    >
      <Loading
        :active.sync="loading"
        :isFullPage="false"
        :zIndex="9000"
      ></Loading>
      <Message
        severity="error"
        v-if="
          expenditureId &&
          expenditureModel.rejectReason &&
          expenditureModel.rejectReason != '' &&
          inApprove == false
        "
        :closable="false"
        ><b>Despesa Rejeitada: </b> {{ expenditureModel.rejectReason }}</Message
      >
      <form
        name="ExpenditureForm"
        class="p-col-12"
        @submit.prevent="save"
        style="min-height: 60vh"
      >
        <div class="p-fluid p-formgrid p-grid" style="min-height: 20vh">
          <div class="p-field p-col-12 p-md-3">
            <div class="p-inputgroup">
              <span class="p-inputgroup-addon">
                <i class="pi pi-calendar"></i>
              </span>
              <span class="p-float-label">
                <v-date-picker
                  v-model="expenditureModel.date"
                  :name="'date-picker'"
                  :id="'date-picker'"
                  v-validate="'required'"
                  :max-date="new Date()"
                  :masks="{
                    input: 'YYYY-MM-DD',
                  }"
                  @input="dateChanged"
                >
                  <template v-slot="{ inputValue, inputEvents }">
                    <span class="p-float-label">
                      <input
                        :name="'date'"
                        :id="'date'"
                        :disabled="editable == false || inApprove == true"
                        v-bind:class="[
                          {
                            'p-error': errors.has('date'),
                          },
                          'form-control',
                          'p-inputtext',
                          'p-component',
                          'p-filled',
                        ]"
                        :value="inputValue"
                        v-on="
                          editable == true && inApprove == false
                            ? inputEvents
                            : ''
                        "
                        v-validate="'required'"
                      />
                      <label for="date">Data da Despesa</label>
                    </span>
                  </template>
                </v-date-picker>
              </span>
            </div>
            <small
              v-if="errors.has('date')"
              class="p-error p-ml-5"
              role="alert"
            >
              Data é obrigatório
            </small>
          </div>
          <div class="p-field p-col-12 p-md-4" v-if="expenditureModel.date">
            <span
              class="p-float-label"
              style="top: -0.75rem; left: -0.5rem; font-size: 12px"
            >
              <label>Despesa Referente a:</label>
            </span>
            <div class="p-field-radiobutton p-d-inline p-mt-2">
              <RadioButton
                id="ref-to-pat"
                name="ref-to-pat"
                value="pat"
                v-model="referenceTo"
                @change="referenceToChanged"
                :disabled="editable == false"
                v-if="user.department != 'Comercial'"
              />
              <label
                for="ref-to-pat"
                class="p-mr-3"
                v-if="user.department != 'Comercial'"
                >PAT</label
              >
              <RadioButton
                id="ref-to-installation"
                name="ref-to-installation"
                value="installation"
                v-model="referenceTo"
                @change="referenceToChanged"
                :disabled="editable == false"
              />
              <label for="ref-to-installation" class="p-mr-3">Instalação</label>
              <RadioButton
                id="ref-to-business"
                name="ref-to-business"
                value="business"
                v-model="referenceTo"
                @change="referenceToChanged"
                :disabled="editable == false"
                v-if="user.department == 'Comercial'"
              />
              <label for="ref-to-business" v-if="user.department == 'Comercial'"
                >Negócio</label
              >
            </div>
          </div>
          <div
            class="p-field p-col-12 p-md-5"
            v-show="this.referenceTo === 'installation'"
          >
            <span class="p-float-label">
              <InputText
                v-if="editable == false"
                type="text"
                v-model="expenditureModel.installationName"
                :disabled="true"
              />
              <Dropdown
                v-else
                id="installation-select"
                inputId="installation-select"
                name="installation-select"
                v-model="expenditureModel.installation"
                v-validate="
                  this.referenceTo === 'installation' ? 'required' : ''
                "
                v-bind:class="[
                  { 'p-invalid': errors.has('installation-select') },
                  'form-control',
                ]"
                :showClear="true"
                :options="installations"
                :optionLabel="'description'"
                :optionValue="'id'"
                :dataKey="'id'"
                :filter="true"
              />
              <label for="installation-select">Instalação</label>
            </span>
            <small
              v-if="errors.has('installation-select')"
              class="p-error"
              role="alert"
            >
              Instalação é obrigatório
            </small>
          </div>
          <div
            class="p-field p-col-12 p-md-5"
            v-show="referenceTo == 'business'"
          >
            <span class="p-float-label">
              <InputText
                v-if="editable == false"
                type="text"
                v-model="expenditureModel.businessName"
                :disabled="true"
              />
              <Dropdown
                v-else
                id="business-select"
                inputId="business-select"
                name="business-select"
                v-model="expenditureModel.business"
                v-validate="referenceTo == 'business' ? 'required' : ''"
                v-bind:class="[
                  { 'p-invalid': errors.has('business-select') },
                  'form-control',
                ]"
                :showClear="true"
                :options="business"
                :optionLabel="'name'"
                :optionValue="'reference'"
                :dataKey="'reference'"
                :filter="true"
              />
              <label for="business-select">Negócio</label>
            </span>
            <small
              v-if="errors.has('business-select')"
              class="p-error"
              role="alert"
            >
              Negócio é obrigatório
            </small>
          </div>
          <div
            class="p-field p-col-12 p-md-5"
            v-show="this.referenceTo === 'pat'"
          >
            <span class="p-float-label">
              <InputText
                v-if="editable == false"
                type="text"
                v-model="expenditureModel.patName"
                :disabled="true"
              />
              <Dropdown
                v-else
                id="pat-select"
                inputId="pat-select"
                name="pat-select"
                v-model="expenditureModel.pat"
                v-validate="this.referenceTo === 'pat' ? 'required' : ''"
                v-bind:class="[
                  { 'p-invalid': errors.has('pat-select') },
                  'form-control',
                ]"
                :options="pats"
                :optionLabel="
                  (info) => (info ? info.id + ' | ' + info.name : '')
                "
                :optionValue="'id'"
                :dataKey="'id'"
                :showClear="true"
                :filter="true"
              >
                <template #option="slotProps">
                  <span
                    v-if="$isMobile() && slotProps.option.id"
                    class="options-search-responsive"
                  >
                    {{ slotProps.option.id }} | {{ slotProps.option.name }}
                  </span>
                  <span v-else-if="slotProps.option.id">
                    {{ slotProps.option.id }} | {{ slotProps.option.name }}
                  </span>
                </template>
              </Dropdown>
              <label for="pat-select">Pat</label>
            </span>
            <small v-if="errors.has('pat-select')" class="p-error" role="alert">
              Pat é obrigatório
            </small>
          </div>
          <div
            class="p-field p-col-12 p-md-3 p-mt-2"
            v-show="
              expenditureModel.installation ||
              expenditureModel.business ||
              expenditureModel.pat ||
              inApprove == true
            "
          >
            <span class="p-float-label">
              <Dropdown
                :disabled="editable == false"
                :name="'type'"
                :id="'type'"
                v-model="expenditureModel.type_id"
                :options="types"
                :optionLabel="'name'"
                :optionValue="'reference'"
                :dataKey="'reference'"
                v-validate="'required'"
                @change="typeChanged"
                v-bind:class="[
                  {
                    'p-invalid': errors.has('type'),
                  },
                  'form-control',
                ]"
              />
              <label for="pats">Tipo</label>
            </span>
            <small v-if="errors.has('type')" class="p-error" role="alert">
              Tipo é obrigatório
            </small>
          </div>
          <div
            class="p-field p-col-12 p-md-3 p-mt-2"
            v-show="
              expenditureModel.installation ||
              expenditureModel.business ||
              expenditureModel.pat ||
              inApprove == true
            "
          >
            <span class="p-float-label">
              <InputText
                :disabled="editable == false"
                :id="'document_reference'"
                :name="'document_reference'"
                type="text"
                v-model="expenditureModel.document_reference"
                v-validate="'required'"
                v-bind:class="[
                  {
                    'p-invalid': errors.has('document_reference'),
                  },
                  'form-control',
                ]"
              />
              <label for="pats">N.º Documento</label>
            </span>
            <small
              v-if="errors.has('document_reference')"
              class="p-error"
              role="alert"
            >
              N.º Documento é obrigatório
            </small>
          </div>
          <div
            class="p-field p-col-12 p-md-3 p-mt-2"
            v-show="
              expenditureModel.installation ||
              expenditureModel.business ||
              expenditureModel.pat ||
              inApprove == true
            "
          >
            <span class="p-float-label">
              <InputNumber
                :disabled="editable == false"
                type="text"
                v-model="expenditureModel.value"
                mode="currency"
                currency="EUR"
                locale="pt-PT"
                v-validate="'required'"
                v-bind:class="[
                  {
                    'p-invalid': errors.has('value'),
                  },
                  'form-control',
                ]"
                :id="'value'"
                :name="'value'"
              />
              <label for="pats">Valor da Despesa</label>
            </span>
            <small v-if="errors.has('value')" class="p-error" role="alert">
              Valor da Despesa é obrigatório
            </small>
          </div>
          <div
            class="p-field p-col-12 p-md-3 p-mt-2"
            v-show="
              expenditureModel.installation ||
              expenditureModel.business ||
              expenditureModel.pat ||
              inApprove == true
            "
          >
            <span class="p-float-label">
              <InputNumber
                :disabled="editable == false"
                type="text"
                v-model="expenditureModel.value_pay"
                mode="currency"
                currency="EUR"
                locale="pt-PT"
                v-validate="'required|max_value:' + expenditureModel.value"
                v-bind:class="[
                  {
                    'p-invalid': errors.has('value_pay'),
                  },
                  'form-control',
                ]"
                :id="'value_pay'"
                :name="'value_pay'"
              />
              <label for="pats">Valor a Pagar</label>
            </span>
            <small v-if="errors.has('value_pay')" class="p-error" role="alert">
              Valor a Pagar é obrigatório. E tem que ser menor ou igual que o
              valor da despesa
            </small>
          </div>
          <div
            class="p-field p-col-12 p-md-3 p-mt-2"
            v-show="
              (expenditureModel.installation ||
                expenditureModel.business ||
                expenditureModel.pat ||
                inApprove == true) &&
              expenditureModel.type_id != undefined &&
              (expenditureModel.type_id.indexOf('VP19061947606,731400472') !=
                -1 ||
                expenditureModel.type_id.indexOf('SA19091651730,627844223') !=
                  -1 ||
                expenditureModel.type_id.indexOf('SA19091652833,731701719') !=
                  -1)
            "
          >
            <span class="p-float-label">
              <InputMask
                v-if="
                  editable == false ||
                  expenditureModel.date < new Date('2023-12-01')
                "
                name="car_registration"
                id="car_registration"
                v-model="expenditureModel.car_registration"
                :disabled="editable == false"
                mask="**-**-**"
                class="p-column-filter"
                v-validate="carRegistrationRequired()"
                v-bind:class="[
                  {
                    'p-invalid': errors.has('car_registration'),
                  },
                  'form-control',
                ]"
              />
              <Dropdown
                v-else
                id="car_registration"
                inputId="car_registration"
                name="car_registration"
                v-model="expenditureModel.car_registration"
                v-validate="carRegistrationRequired()"
                v-bind:class="[
                  { 'p-invalid': errors.has('car_registration') },
                  'form-control',
                ]"
                :showClear="true"
                :options="vehicles"
                :optionLabel="
                  (info) =>
                    info.registration == '00-00-00'
                      ? info.model
                      : info.registration
                "
                :optionValue="'registration'"
                :dataKey="'id'"
                :filter="true"
              >
                <template #option="slotProps">
                  <span v-if="slotProps.option.registration != '00-00-00'"
                    >{{ slotProps.option.registration }} |
                  </span>
                  {{ slotProps.option.model }}
                </template>
              </Dropdown>
              <label for="pats">Matrícula</label>
            </span>
            <small
              v-if="errors.has('car_registration')"
              class="p-error"
              role="alert"
            >
              Matrícula é obrigatório
            </small>
          </div>
          <div
            class="p-field p-col-12 p-md-3 p-mt-2"
            v-show="
              (expenditureModel.installation ||
                expenditureModel.business ||
                expenditureModel.pat ||
                inApprove == true) &&
              expenditureModel.type_id != undefined &&
              expenditureModel.type_id.indexOf('VP19061947606,731400472') != -1
            "
          >
            <span class="p-float-label">
              <InputNumber
                :disabled="editable == false"
                v-model="expenditureModel.car_km"
                v-validate="carKmRequired()"
                v-bind:class="[
                  {
                    'p-invalid': errors.has('car_km'),
                  },
                  'form-control',
                ]"
                :id="'car_km'"
                :name="'car_km'"
              />
              <label for="pats">Km</label>
            </span>
            <small v-if="errors.has('car_km')" class="p-error" role="alert">
              Km é obrigatório
            </small>
          </div>
          <div></div>
          <div
            class="p-field p-col-12 p-md-6 p-mt-2"
            v-show="
              expenditureModel.installation ||
              expenditureModel.business ||
              expenditureModel.pat ||
              inApprove == true
            "
          >
            <span class="p-float-label">
              <InputText
                :disabled="editable == false"
                type="text"
                v-model="expenditureModel.comments"
              />
              <label for="pats">Observações</label>
            </span>
          </div>

          <div class="p-field p-grid p-col-12" v-if="inApprove == true">
            <divider />
            <div class="p-field p-col-12 p-md-4 p-mt-3">
              <span class="p-float-label">
                <Textarea
                  v-model="expenditureModel.rejectReason"
                  v-validate="
                    expenditureModel.approved == false ? 'required' : ''
                  "
                  :autoResize="true"
                  rows="3"
                  v-bind:class="[
                    {
                      'p-invalid': errors.has('rejectReason'),
                    },
                    'form-control',
                  ]"
                  :id="'rejectReason'"
                  :name="'rejectReason'"
                />
                <label for="rejectReason">Razão de Não Ser Aprovada</label>
              </span>
              <small
                v-if="errors.has('rejectReason')"
                class="p-error"
                role="alert"
              >
                Razão de Não Ser Aprovada é obrigatório
              </small>
            </div>
            <div class="p-field p-col-12 p-md-1 p-mt-3">
              <span
                class="p-float-label"
                style="top: -0.75rem; left: -1rem; font-size: 12px"
              >
                <label for="pats">Aprovada</label>
              </span>
              <InputSwitch
                v-model="expenditureModel.approved"
                :id="'approved'"
                :inputId="'approved'"
                :name="'approved'"
                style="'top: 3px;"
              />
            </div>
            <divider />
          </div>
          <div class="p-field p-grid p-col-12">
            <FileUpload
              ref="uploadInput"
              mode="basic"
              name="files[]"
              chooseLabel="Adicionar Ficheiro"
              @select="fileSelected"
              v-if="
                (expenditureModel.installation ||
                  expenditureModel.business ||
                  expenditureModel.pat) &&
                editable == true &&
                inApprove == false
              "
            />
            <small
              v-if="
                expenditureModel.filesError != undefined &&
                expenditureModel.filesError == true
              "
              class="p-error"
              role="alert"
            >
              Não foi adicionado nenhum ficheiro
            </small>
            <div class="p-col-12 p-md-2" v-if="expenditureId">
              <Button
                v-if="
                  expenditureModel.viewFileButton != undefined &&
                  expenditureModel.viewFileButton == true
                "
                label="Ver Ficheiros"
                icon="pi pi-eye"
                class="p-button-success"
                style="width: 100%"
                @click="getExpenditureFiles"
              />
              <Loading
                :active.sync="expenditureModel.looderFiles"
                :is-full-page="false"
              ></Loading>
              <div
                class="p-field p-col-12 p-md-12"
                v-for="file in expenditureModel.files"
                :key="file.referece"
              >
                <div v-if="file.type == 'pdf'" class="pdf-viewer">
                  <embed
                    :src="file.src"
                    type="application/pdf"
                    width="100%"
                    height="300px"
                  />
                </div>
                <div v-else style="text-align: center">
                  <ImagePreview
                    :src="file.src"
                    alt="Image"
                    width="250"
                    preview
                  />
                </div>
                <div class="p-col-12 p-text-center p-mt-1">
                  <Button
                    v-if="file.type == 'pdf'"
                    icon="pi pi-search"
                    class="p-button-rounded p-button-outlined p-button-raised"
                    aria:haspopup="true"
                    aria-controls="overlay_panel"
                    @click="file.fullView = true"
                  />
                  <Sidebar :visible.sync="file.fullView" position="full">
                    <div v-if="file.type == 'pdf'" class="pdf-fullview">
                      <embed
                        :src="file.src"
                        type="application/pdf"
                        width="100%"
                        height="500px"
                      />
                    </div>
                  </Sidebar>
                  <Button
                    v-if="
                      inApprove == false &&
                      editable == true &&
                      expenditureId &&
                      expenditureModel.files.length > 1
                    "
                    icon="pi pi-times"
                    class="p-button-rounded p-button-danger p-button-outlined p-button-raised p-ml-1"
                    @click="removeFile(file.reference)"
                  />
                </div>
              </div>
            </div>
          </div>
        </div>
      </form>
      <template #footer>
        <Button
          v-if="editable == true"
          :label="'Guardar'"
          icon="pi pi-check"
          class="p-button-success"
          @click="save"
        />
        <Button
          label="Cancelar"
          icon="pi pi-times"
          class="p-button-danger"
          @click="cancel"
        />
      </template>
    </Dialog>
  </div>
</template>
<script>
import Loading from "vue-loading-overlay";
import "vue-loading-overlay/dist/vue-loading.css";
import expenditureService from "../../services/expenditure.service";
import filesService from "../../services/files.service";
import utilitiesService from "../../services/utilities.service";
import interventionService from "../../services/intervention.service";
import fleetService from "../../services/fleet.service";
import { getOnlyDate } from "../../helpers/helpers";
export default {
  name: "ExpenditureForm",
  props: ["show", "expenditureId", "userId", "inApprove"],
  components: { Loading },
  watch: {
    async expenditureId() {
      this.$validator.pause();
      this.cleanData();
      if (this.expenditureId != undefined) {
        this.loading = true;
        this.reset = false;
        await this.getInfo();
        this.reset = true;
        this.loading = false;
      }
      if (this.show == true) {
        this.$validator.resume();
      }
    },
  },
  data() {
    return {
      loading: false,
      pats: [],
      business: [],
      installations: [],
      types: [],
      vehicles: [],
      referenceTo: null,
      expenditureModel: { filesError: false },
      reset: true,
      editable: true,
      user: null,
      approved: false,
    };
  },
  async created() {
    if (this.inApprove == undefined) {
      this.inApprove = false;
    }
    this.types = await this.getItemsTypes();
    this.user = this.$store.state.auth.user;
  },
  methods: {
    cleanData() {
      this.pats = [];
      this.referenceTo = null;
      this.reset = true;
      this.editable = true;
      this.approved = false;
      this.expenditureModel = { filesError: false };
    },
    async getInfo() {
      return expenditureService
        .getExpenditure(this.expenditureId)
        .then((response) => {
          if (this.inApprove == true) {
            this.user = {
              id: response.userId,
              username: response.user,
              department: response.userDepartment,
            };
          }
          this.editable = !response.approved;
          this.referenceTo =
            response.business != ""
              ? "business"
              : response.installation != ""
              ? "installation"
              : "pat";
          this.expenditureModel = {
            rejectReason: response.rejectReason,
            files: [],
            viewFileButton: true,
            approved: response.approved,
            date: new Date(response.day),
            installation:
              this.referenceTo == "installation"
                ? `${response.installation}`
                : null,
            installationName: response.installationName,
            business: this.referenceTo == "business" ? response.business : null,
            businessName: response.businessName,
            pat: this.referenceTo == "pat" ? response.pat : null,
            patName: response.patName,
            type_id: response.type_id,
            document_reference: response.document_reference,
            value: response.value,
            value_pay: response.value_pay,
            car_registration: response.car_registration,
            car_km: response.car_km,
            comments: response.comments,
            reference: response.reference,
          };
          if (this.editable == true) {
            this.loading = true;
            this.referenceToChanged();
            this.loading = false;
          }
        });
    },
    getItemsTypes() {
      return expenditureService
        .getUserExpenditureItemsTypes()
        .then((response) => {
          response.forEach((type) => {
            type.reference = type.reference.replace(/ /g, "");
          });
          return response;
        });
    },
    async referenceToChanged() {
      if (this.editable == false) {
        return;
      }
      if (this.reset === true) {
        this.$validator.reset();
        this.expenditureModel.business = null;
        this.expenditureModel.installation = null;
        this.expenditureModel.pat = null;
      }
      if (
        this.referenceTo === "installation" &&
        this.installations.length == 0
      ) {
        this.loading = true;
        this.installations = await utilitiesService.getInstallations();
        this.loading = false;
      } else if (this.referenceTo === "business" && this.business == 0) {
        this.loading = true;
        this.business = await utilitiesService.getBusiness();
        this.business.forEach((business) => {
          business.reference = `${business.reference}`.replace(/ /g, "");
        });
        this.loading = false;
      } else if (this.referenceTo === "pat") {
        this.loading = true;
        this.pats = await this.getPats();
        this.loading = false;
      }
      this.$validator.resume();
    },
    async getPats() {
      let date = getOnlyDate(this.expenditureModel.date);
      let pats = await interventionService.getInterventionsPat(
        this.userId,
        date,
        date
      );
      if (!Array.isArray(pats)) {
        pats = Object.values(pats);
      }
      if (this.user.department == "Tecnico") {
        let supportPat = pats.find((pat) => pat.id == 837);
        if (supportPat == undefined) {
          pats.push({
            id: 837,
            name: "W4M Departamento Suporte",
            nopat: 837,
            resume: "Suporte interno",
          });
        }
      }

      return pats;
    },
    async dateChanged() {
      if (this.editable == false) {
        return;
      }
      if (this.referenceTo === "pat") {
        this.loading = true;
        if (this.reset == true) {
          this.expenditureModel.pat = null;
          this.expenditureModel.car_registration = null;
        }
        this.pats = await this.getPats();
        this.loading = false;
      }
      await this.typeChanged();
    },
    async typeChanged() {
      if (
        this.expenditureModel.type_id &&
        this.expenditureModel.type_id.indexOf("VP19061947606,731400472") ==
          -1 &&
        this.expenditureModel.type_id.indexOf("SA19091651730,627844223") ==
          -1 &&
        this.expenditureModel.type_id.indexOf("SA19091652833,731701719") == -1
      ) {
        return;
      }
      let date = getOnlyDate(this.expenditureModel.date);
      this.vehicles = await fleetService
        .searchVehiclesByUserDate(this.userId, date)
        .then((response) => {
          response.push({
            id: 0,
            registration: "00-00-00",
            model: "Viatura Própria",
          });
          return response;
        });
    },
    carRegistrationRequired() {
      if (
        this.expenditureModel.type_id != undefined &&
        (this.expenditureModel.type_id.indexOf("VP19061947606,731400472") !=
          -1 ||
          this.expenditureModel.type_id.indexOf("SA19091651730,627844223") !=
            -1 ||
          this.expenditureModel.type_id.indexOf("SA19091652833,731701719") !=
            -1)
      ) {
        return "required";
      }
      return "";
    },
    carKmRequired() {
      if (
        this.expenditureModel.type_id != undefined &&
        this.expenditureModel.type_id.indexOf("VP19061947606,731400472") != -1
      ) {
        return "required";
      }
      return "";
    },
    cancel() {
      this.$validator.pause();
      this.cleanData();
      return this.$emit("cancel");
    },
    save() {
      this.$validator.validateAll().then((isValid) => {
        if (!this.expenditureId && this.$refs.uploadInput.files.length == 0) {
          this.expenditureModel.filesError = true;
          return (this.loading = false);
        } else {
          this.expenditureModel.filesError = false;
        }
        if (!isValid) {
          this.loading = false;
          return;
        }
        this.$validator.pause();
        let bodyParam = {
          user_id: this.user.id,
          date:
            this.expenditureModel.date instanceof Date
              ? getOnlyDate(this.expenditureModel.date)
              : this.expenditureModel.date,
          business_id: this.expenditureModel.business,
          installation_id: this.expenditureModel.installation,
          pat_id: this.expenditureModel.pat,
          type_id: this.expenditureModel.type_id,
          value: this.expenditureModel.value,
          value_pay: this.expenditureModel.value_pay,
          document_reference: this.expenditureModel.document_reference,
          comments: this.expenditureModel.comments
            ? this.expenditureModel.comments
            : "",
        };

        if (this.expenditureModel.car_registration) {
          bodyParam.car_registration = this.expenditureModel.car_registration;
        }
        if (this.expenditureModel.car_km) {
          bodyParam.car_km = this.expenditureModel.car_km;
        }
        if (this.expenditureId === undefined || this.expenditureId === null) {
          return this.createNew(bodyParam);
        } else if (this.editable == true) {
          return this.update(bodyParam);
        }
      });
    },
    fileSelected() {
      this.expenditureModel.filesError = false;
      this.$validator.validateAll();
    },
    createNew(params) {
      this.loading = true;
      return expenditureService
        .createExpenditure(params)
        .then((response) => {
          if (response.status >= 400) {
            this.loading = false;
            return this.$toast.add({
              severity: "error",
              summary: "Erro ao Criar Despesa",
              detail: response.data.message,
              life: 5000,
            });
          }

          this.$toast.add({
            severity: "success",
            summary: "Despesa Criada",
            detail: "A despesa foi Criada com Sucesso",
            life: 5000,
          });
          return response.data;
        })
        .then((expend) => {
          this.myUploader(this.$refs.uploadInput.files[0], expend);
          return expend;
        })
        .then((res) => {
          this.loading = false;
          this.cleanData();
          return this.$emit("expenditureSaved", {
            expenditure: res,
          });
        });
    },
    update(params) {
      this.loading = true;
      expenditureService
        .updateExpenditure(this.expenditureId, params)
        .then((response) => {
          if (response.status >= 400) {
            this.loading = false;
            this.$toast.add({
              severity: "error",
              summary: "Erro ao atualizar",
              detail: response.data.message,
              life: 5000,
            });
            return false;
          }
          this.$toast.add({
            severity: "success",
            summary: "Despesa Atualizada",
            detail: "A despesa foi Atualizada com Sucesso",
            life: 5000,
          });
          return response.data;
        })
        .then((expend) => {
          if (expend == false) {
            return false;
          }
          if (
            this.inApprove == false &&
            this.$refs.uploadInput.files.length == 1
          ) {
            this.myUploader(this.$refs.uploadInput.files[0], expend);
          }
          return expend;
        })
        .then((res) => {
          this.loading = false;
          if (res == false) {
            return res;
          }
          return this.$emit("expenditureSaved", {
            expenditure: res,
          });
        })
        .then((resp) => {
          if (resp == false) {
            return false;
          }
          if (this.inApprove == false) {
            return false;
          }
          let param = {
            approved: this.expenditureModel.approved,
            rejectReason:
              this.expenditureModel.approved == false
                ? this.expenditureModel.rejectReason
                : "",
          };
          return expenditureService
            .approveExpenditure(this.expenditureId, param)
            .then((status) => {
              if (status == false || status.status >= 400) {
                this.loading = false;
                this.$toast.add({
                  severity: "error",
                  summary: "Erro ao Aprovar",
                  detail: status.data.message,
                  life: 5000,
                });
                return false;
              }

              this.$toast.add({
                severity: "success",
                summary: `Despesa ${
                  status.approved == 1 ? "Aprovada" : "Rejeitada"
                }`,
                detail: `A despesa foi ${
                  status.approved == 1 ? "Aprovada" : "Rejeitada"
                } com Sucesso`,
                life: 5000,
              });
              this.loading = false;
              return this.$emit("expenditureApproved", {
                approved: status.approved,
                reason: param.rejectReason,
              });
            });
        });
    },
    myUploader(file, expenditure) {
      let formData = new FormData();

      formData.append("file", file);
      formData.append("origin", "DS");
      formData.append("origin_reference", expenditure.reference);
      formData.append("resume", expenditure.description);

      return filesService.addFile(formData).then((response) => {
        if (!response) {
          return this.$toast.add({
            severity: "error",
            summary: "Erro",
            detail: "Ocorreu um erro ao inserir o ficheiro",
            life: 5000,
          });
        }

        return this.$toast.add({
          severity: "success",
          summary: "Ficheiro inserido",
          detail: "O Ficheiro inserido com sucesso",
          life: 5000,
        });
      });
    },
    removeFile(fileReference) {
      const fileAreYou = (fileAux) => {
        return fileAux.reference == fileReference;
      };

      this.$confirm.require({
        header: "Despesa - Apagar Ficheiro",
        message: "Tem a certeza que pretende apagar este ficheiro?",
        icon: "pi pi-exclamation-triangle",
        acceptLabel: "Sim",
        acceptIcon: "pi pi-check",
        acceptClass: "p-button-success p-button p-component",
        rejectLabel: "Não",
        rejectIcon: "pi pi-times",
        rejectClass: "p-button-danger p-button p-component",
        accept: () => {
          let data = {
            reference: fileReference,
          };
          filesService.removeFile(data).then((response) => {
            if (!response) {
              return this.$toast.add({
                severity: "error",
                summary: "Erro",
                detail: "Ocorreu um erro ao eliminar o ficheiro",
                life: 5000,
              });
            }

            let fileIndex = this.expenditureModel.files.findIndex(fileAreYou);
            this.expenditureModel.files.splice(fileIndex, 1);

            return this.$toast.add({
              severity: "success",
              summary: "Ficheiro eliminado",
              detail: "O ficheiro foi eliminado com sucesso",
              life: 5000,
            });
          });
        },
        reject: () => {},
      });
    },
    getExpenditureFiles() {
      this.expenditureModel.files = [];
      this.expenditureModel.looderFiles = true;
      let data = {
        origin_reference: this.expenditureModel.reference,
      };
      filesService.getFileFromOriginReference(data).then((response) => {
        if (!response) {
          return this.$toast.add({
            severity: "error",
            summary: "Erro",
            detail: "Ocorreu um erro ao eliminar o ficheiro",
            life: 5000,
          });
        }
        response.forEach((file) => {
          file["fullView"] = false;
          if (file.type !== undefined && file.type == "pdf") {
            file.src = `data:application/${file.type};base64,${file.src}`;
          } else if (file.type !== undefined) {
            file.src = `data:image/${file.type};base64,${file.src}`;
          }

          this.expenditureModel.files.push(file);
        });
        this.expenditureModel.viewFileButton = false;
        this.expenditureModel.looderFiles = false;
      });
    },
  },
};
</script>
