import axios from "axios";
import authHeader from "./auth-header";
import AuthService from "./auth.service";
import { config } from "../config";

class ExpenditureService {
  getUserExpenditureItemsTypes() {
    return axios
      .get(`${config["apiUrl"]}expenditure/types`, {
        headers: authHeader(),
      })
      .then((response) => {
        if (response.status == 200) {
          return response.data;
        }
        return null;
      })
      .catch((error) => {
        if (error.response.status == 401) {
          AuthService.logout();
          window.location = "/login";
        }
        console.log("ERROR:: ", error.response.data);
        return null;
      });
  }

  getUserExpenditureByYear(userId, year) {
    return axios
      .get(`${config["apiUrl"]}expenditure/user/${userId}/year/${year}`, {
        headers: authHeader(),
      })
      .then((response) => {
        if (response.status == 200) {
          return response.data;
        }
        return null;
      })
      .catch((error) => {
        if (error.response.status == 401) {
          AuthService.logout();
          window.location = "/login";
        }
        console.log("ERROR:: ", error.response.data);
        return null;
      });
  }

  deleteExpenditure(expenditureId) {
    return axios
      .delete(`${config["apiUrl"]}expenditure/${expenditureId}`, {
        headers: authHeader(),
      })
      .then((response) => {
        if (response.status == 200) {
          return response.data;
        }
        return null;
      })
      .catch((error) => {
        if (error.response.status == 401) {
          AuthService.logout();
          window.location = "/login";
        }
        console.log("ERROR:: ", error.response.data);
        return null;
      });
  }

  createExpenditure(bodyParms) {
    return axios
      .post(`${config["apiUrl"]}expenditure`, bodyParms,  {
        headers: authHeader(),
      })
      .then((response) => {
        return response;
      })
      .catch((error) => {
        if (error.response.status == 401) {
          AuthService.logout();
          window.location = "/login";
        }
        console.log("ERROR:: ", error.response.data);
        return error.response;
      });
  }

  getExpenditureDetails(expenditureId) {
    return axios
      .get(`${config["apiUrl"]}expenditure/${expenditureId}/details`, {
        headers: authHeader(),
      })
      .then((response) => {
        if (response.status == 200) {
          return response.data;
        }
        return null;
      })
      .catch((error) => {
        if (error.response.status == 401) {
          AuthService.logout();
          window.location = "/login";
        }
        console.log("ERROR:: ", error.response.data);
        return null;
      });
  }

  getExpenditure(expenditureId) {
    return axios
      .get(`${config["apiUrl"]}expenditure/${expenditureId}`, {
        headers: authHeader(),
      })
      .then((response) => {
        if (response.status == 200) {
          return response.data;
        }
        return null;
      })
      .catch((error) => {
        if (error.response.status == 401) {
          AuthService.logout();
          window.location = "/login";
        }
        console.log("ERROR:: ", error.response.data);
        return null;
      });
  }

  updateExpenditure(expenditureId, bodyParms) {
    return axios
      .patch(`${config["apiUrl"]}expenditure/${expenditureId}`, bodyParms,  {
        headers: authHeader(),
      })
      .then((response) => {
        return response;
      })
      .catch((error) => {
        if (error.response.status == 401) {
          AuthService.logout();
          window.location = "/login";
        }
        console.log("ERROR:: ", error.response.data);
        return error.response;
      });
  }

  getExpenditureNotAproved(year) {
    return axios
      .get(`${config["apiUrl"]}expenditure/list_not_approved/year/${year}`, {
        headers: authHeader(),
      })
      .then((response) => {
        if (response.status == 200) {
          return response.data;
        }
        return null;
      })
      .catch((error) => {
        if (error.response.status == 401) {
          AuthService.logout();
          window.location = "/login";
        }
        console.log("ERROR:: ", error.response.data);
        return null;
      });
  }

  approveExpenditure(expenditureId, items) {
    return axios
      .put(`${config["apiUrl"]}expenditure/${expenditureId}`, items,  {
        headers: authHeader(),
      })
      .then((response) => {
          return response.data;
      })
      .catch((error) => {
        if (error.response.status == 401) {
          AuthService.logout();
          window.location = "/login";
        }
        console.log("ERROR:: ", error.response.data);
        return error.response;
      });
  }

  countRejected() {
    return axios
      .get(`${config["apiUrl"]}expenditure/rejected`, {
        headers: authHeader(),
      })
      .then((response) => {
        if (response.status == 200) {
          return response.data;
        }
        return null;
      })
      .catch((error) => {
        if (error.response.status == 401) {
          AuthService.logout();
          window.location = "/login";
        }
        console.log("ERROR:: ", error.response.data);
        return null;
      });
  }
}
export default new ExpenditureService();
